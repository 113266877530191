import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default () => {
  const navigate = useNavigate()
  // @ts-expect-error TS2339
  const navigationState = useSelector((state) => state.navigation)

  useEffect(() => {
    switch (navigationState.action) {
      case 'PUSH':
        return navigate(navigationState.url)

      case 'REPLACE':
        return navigate(navigationState.url, {
          replace: true,
        })

      case 'BACK':
        return navigate(-1)

      default: // do nothing
    }
  }, [navigationState])

  return null
}
