import { decamelizeKeys } from 'humps'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import queryString from 'query-string'

import serializeOnboardingSearch from '~/serializers/onboarding-search'
import serializeProfileSearch from '~/serializers/profile-search'

import type { AdminAPIEndpoints, ProfileAuditEventsV2Params } from '~/types'

export default function getAdminAPIEndpoints(
  guestId: string,
): AdminAPIEndpoints {
  const adminApi = '/admin/api'
  const guestPath = `${adminApi}/guests/${guestId}`
  const cartPath = `${guestPath}/cart`

  const endpoints = {
    acknowledgeCartChangePath: `${guestPath}/cart/changed`,
    addUpgradesToCartPath: `${cartPath}/upgrades`,
    adminCartConfigurationPath: `${guestPath}/admin-cart-configuration`,
    adminsPath: `${adminApi}/admins`,
    adminPath: (adminId: number) => `${adminApi}/admins/${adminId}`,
    backdatingPath: `${guestPath}/cart/backdating`,
    benefitPath: (benefitId: number) => `${adminApi}/benefits/${benefitId}`,
    benefitProductsPath: (benefitId: number) =>
      `${endpoints.benefitPath(benefitId)}/products`,
    benefitTranslationsPath: (benefitId: number) =>
      `${endpoints.benefitPath(benefitId)}/translations`,
    benefitsPath: `${adminApi}/benefits`,
    benefitsClonePath: `${adminApi}/benefits/clone`,
    callCentersPath: `${adminApi}/call-centers`,
    cartAddonsPath: `${cartPath}/addons`,
    cartCreditCardPath: `${cartPath}/credit-card`,
    cartItemPath: (internalId: string) =>
      `${guestPath}/cart/items/${internalId}`,
    cartItemPromotionPromptPath: (internalId: string) =>
      `${guestPath}/cart/items/${internalId}/promotion-prompts`,
    cartItemsAssignPath: (internalId: string) =>
      `${cartPath}/items/${internalId}/assign`,
    cartItemsCreditDismissPath: (internalId: string) =>
      `${cartPath}/items/${internalId}/credit`,
    cartItemsPath: `${cartPath}/items`,
    cartPath,
    cartPromotionsPath: `${cartPath}/promotion`,
    cartUpgradePath: `${cartPath}/upgrade`,
    creditCardOnFilePath: (profileId) =>
      `${adminApi}/profiles/${profileId}/credit-card-on-file`,
    creditCreatePath: `${adminApi}/credits`,
    creditResetPath: (creditId: number) =>
      `${adminApi}/credits/${creditId}/reset`,
    creditRevokePath: (creditId: number) =>
      `${adminApi}/credits/${creditId}/revoke`,
    creditsPath: (profileId: string) =>
      `${adminApi}/profiles/${profileId}/credits`,
    creditTypePath: (creditId: number) =>
      `${adminApi}/credit-types/${creditId}`,
    creditTypesPath: `${adminApi}/credit-types`,
    currentAdminPath: `${adminApi}/me`,
    deleteProductCreditExpirationsPath: (productCreditExpirationId: number) =>
      `${adminApi}/product-credit-expirations/${productCreditExpirationId}`,
    emailCartLinkPath: `${cartPath}/notify`,
    entitlementTypePath: (entitlementTypeId: number) =>
      `${adminApi}/entitlement-types/${entitlementTypeId}`,
    facebookConversionsPath: `${guestPath}/facebook-conversions`,
    fullyExpireProductCreditExpirationPath: (
      productCreditExpirationId: number,
    ) =>
      `${adminApi}/product-credit-expirations/${productCreditExpirationId}/fully-expire`,
    getOrderProductsPath: (orderId: string) =>
      `${adminApi}/orders/${orderId}/products`,
    groupPath: `${guestPath}/group`,
    guestImpersonationPath: `${adminApi}/guest-impersonations`,
    guestPath,
    guestProfilesPath: `${guestPath}/profiles`,
    hotlistsPath: `${adminApi}/hotlists`,
    oauthApplicationsPath: `${adminApi}/oauth/applications`,
    oauthApplicationPath: (applicationId: number) =>
      `${adminApi}/oauth/applications/${applicationId}`,
    oauthApplicationFormDataPath: (applicationId: number) =>
      `${adminApi}/oauth/applications/${applicationId}/edit`,
    oauthAuthorizeApplicationPath: (queryParams: {
      clientId: string
      redirectUri: string
    }) =>
      `/authorize?client_id=${queryParams.clientId}&redirect_uri=${queryParams.redirectUri}&response_type=code`,
    onboardingsPath: (params: Record<string, any>) => {
      // @ts-expect-error TS2345
      const serialized = serializeOnboardingSearch(params)
      return `${adminApi}/onboardings?${queryString.stringify(serialized)}`
    },
    orderPath: (orderId: number | string) => `${adminApi}/orders/${orderId}`,
    ordersPath: `${guestPath}/orders`,
    overrideAffiliationsPath: (profileId: string) =>
      `${cartPath}/profiles/${profileId}/affiliations/override`,
    participantReservationsPath: (profileId: string) =>
      `${adminApi}/profiles/${profileId}/reservations`,
    passwordResetRequestPath: `${adminApi}/password-reset`,
    productCreditExpirationsPath: `${adminApi}/product-credit-expirations`,
    productDuplicatePath: (productId: number) =>
      `${adminApi}/products/${productId}/duplicate`,
    productEntitlementTypesPath: (productId: number) =>
      `${adminApi}/products/${productId}/entitlement-types`,
    productPath: (productId: number) => `${adminApi}/products/${productId}`,
    productFamiliesPath: `${adminApi}/product-families`,
    productPricingWindowsPath: (productId: number) =>
      `${adminApi}/products/${productId}/pricing-windows`,
    productPricingWindowsUpdatePath: (
      pricingWindowId: number,
      productId: number,
    ) => `${adminApi}/products/${productId}/pricing-windows/${pricingWindowId}`,
    productResortsPath: (productId: number) =>
      `${adminApi}/products/${productId}/resorts`,
    productsPath: `${adminApi}/products`,
    productTranslationsPath: (productId: number) =>
      `${adminApi}/products/${productId}/translations`,
    profileAuditEventsV2Path: (params: ProfileAuditEventsV2Params) =>
      `${adminApi}/profiles/${params.profileId}/audit-events-v2?before=${
        params.before
      }&filter=${params.filter || ''}&search=${params.search || ''}&limit=${
        params.limit || ''
      }`,
    profileDetailPath: (profileId: string) =>
      `${adminApi}/profiles/${profileId}/detail`,
    profileGroupMembershipsPath: (profileId: string) =>
      `${adminApi}/profiles/${profileId}/group-memberships`,
    profileMediaPath: (profileId: string, activeMediaOnly?: boolean) => {
      let path = `/admin/api/profiles/${profileId}/media`
      return activeMediaOnly ? (path += `?active_media_only=true`) : path
    },
    profileOrdersPath: (profileId: string) =>
      `${adminApi}/profiles/${profileId}/orders`,
    profilePath: (profileId: string) => `${adminApi}/profiles/${profileId}`,
    profileProductsForProfilePath: (
      profileId: string,
      includeInactive = false,
    ) =>
      `${adminApi}/profiles/${profileId}/my-products?include_inactive=${String(
        includeInactive,
      )}`,
    profileProductsPath: (includeInactive = false) =>
      `${guestPath}/my-products?include_inactive=${String(includeInactive)}`,
    profileProductUpdatePath: (profileProductId: number) =>
      `${adminApi}/my-products/${profileProductId}`,
    profileRedemptionsPath: (profileId: string) =>
      `/admin/api/profiles/${profileId}/redemptions`,
    profilesPath: (params: Record<string, any>) => {
      // @ts-expect-error TS2345
      const serialized = serializeProfileSearch(params)
      return `${adminApi}/profiles?${queryString.stringify(serialized)}`
    },
    profileWaiversPath: (profileId: string) =>
      `${adminApi}/profiles/${profileId}/waivers`,
    promotionCodePath: (promotionCodeId: number) =>
      `${adminApi}/promotion-codes/${promotionCodeId}`,
    redeemableVouchersPath: `${guestPath}/voucher-shares/redeemable-vouchers`,
    removeAllCardsOnFilePath: `${guestPath}/credit-cards`,
    reprocessOrdersPath: `${adminApi}/orders/reprocess`,
    reservationAvailabilityPath: (resortId: number | string) =>
      `${guestPath}/reservation-availability/${resortId}`,
    reservationCartConfirmPath: `${guestPath}/reservation-cart/confirm`,
    reservationCartPath: `${guestPath}/reservation-cart`,
    reservationExceptionsPath: (profileId: string) =>
      `${adminApi}/reservation-exceptions/${profileId}`,
    reservationPath: (reservationId: number) =>
      `${guestPath}/reservations/${reservationId}`,
    reservationsPath: `${guestPath}/reservations`,
    resortAuthorizationCategoriesPath: `${adminApi}/resort-authorization-categories`,
    resortAuthorizationCategoryPath: (resortAuthorizationCategoryId: number) =>
      `${adminApi}/resort-authorization-categories/${resortAuthorizationCategoryId}`,
    resortCapacitiesPath: (resortId: number | string) =>
      `${adminApi}/resorts/${resortId}/resort_capacities`,
    resortCapacityPath: (
      resortId: number | string,
      resortCapacityId: number | string,
    ) =>
      `${adminApi}/resorts/${resortId}/resort_capacities/${resortCapacityId}`,
    resortManagerPermissibleResortsPath: `${adminApi}/admin-resort-management-permissions`,
    resortPath: (resortId: number) => `${adminApi}/resorts/${resortId}`,
    resortReservationsCancelPath: (resortId: number) =>
      `${adminApi}/resorts/${resortId}/reservations/cancel`,
    resortsPath: `${adminApi}/resorts`,
    returnVoucherSharePath: (voucherShareId: number) =>
      `${adminApi}/voucher-shares/${voucherShareId}/revoke`,
    salesAgentReportPath: (queryParams: {
      dateFrom: string
      dateTo: string
      callCenterId: number | null
      adminId: number | null
    }) => {
      return `${adminApi}/sales-agent-report?${queryString.stringify(
        decamelizeKeys(omitBy(queryParams, isNil)),
      )}`
    },
    seasonsPath: `${adminApi}/seasons`,
    selfPurchaseReportPath: (queryParams: {
      dateFrom: string
      dateTo: string
      productCodes: string
    }) => {
      return `${adminApi}/self-purchase-report?${queryString.stringify(
        decamelizeKeys(omitBy(queryParams, isNil)),
      )}`
    },
    useCreditCardOnFilePath: `${cartPath}/credit-card/use-card-on-file`,
    voucherSharePath: `${adminApi}/voucher-shares`,
    vouchersSharedFromProfileProductPath: (profileProductId: number) =>
      `${adminApi}/voucher-shares?shared_from_profile_product_id=${profileProductId}`,
    vouchersSharedToProfilePath: (profileId: string) =>
      `${adminApi}/voucher-shares?shared_to_profile_id=${profileId}`,
  }

  return endpoints
}
